import CheckIcon from "@components/svg/checkIcon";
import { Category, ClickOffer } from "@lib/shared/types";
import React, { ReactElement, useState } from "react";
import ButtonArrow from "../buttonArrow";
import StarIcon from "../svg/starIcon";
import styles from "./styles.module.scss";

export default function ListingBlock({
    item,
    index,
    state,
    category,
    type,
    handleModalClose,
}: {
    item: ClickOffer;
    index: number;
    state: string | null;
    category: Category | null;
    type:
        | "listingModal"
        | "listingPage"
        | "landingPageModal"
        | "categoryListingModal";
    handleModalClose?: () => void;
}): ReactElement {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(true);
        if (
            (type === "listingModal" || type === "categoryListingModal") &&
            handleModalClose
        ) {
            handleModalClose();
        }
    };

    return (
        <div className={`${styles["listing-block"]} ${styles[type]}`}>
            {type === "listingPage" && (
                <div className={styles["order"]}>
                    {index === 0 ? <StarIcon /> : index + 1}
                </div>
            )}
            <div className={styles["logo"]}>
                <img src={item.logoUrl} alt="logo" />
            </div>

            <div className={styles["content"]}>
                <h2
                    className="mb-4 font-bold lg:text-lg"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                ></h2>

                {type !== "categoryListingModal" ? (
                    <div className={styles["features"]}>
                        <ul className="flex flex-col">
                            {item.bulletedDescription?.map((feature, idx) => (
                                <li
                                    key={idx}
                                    className="text-sm font-light mb-2 flex items-start"
                                >
                                    <span className="children:h-3 children:w-3">
                                        <CheckIcon />
                                    </span>

                                    <span
                                        className="ml-2"
                                        dangerouslySetInnerHTML={{
                                            __html: feature,
                                        }}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </div>
            <div
                className={`${styles["cta"]} ${
                    clicked ? styles["clicked"] : ""
                }`}
                style={{ textAlign: "center" }}
            >
                {item.revenue && (
                    <span className="mb-1 block">${item.revenue}</span>
                )}
                <a
                    target="_blank"
                    onClick={handleClick}
                    href={`/${category?.slugAlias ?? ""}/offer/${item.slug}`}
                    rel="noreferrer"
                >
                    {!clicked ? (
                        <span className={styles["main"]}>
                            view deal
                            <ButtonArrow />
                        </span>
                    ) : (
                        <span className={styles["check"]}>
                            <CheckIcon />
                        </span>
                    )}
                </a>
                {index === 0 && (
                    <span className={styles["cta-state"]}>
                        {state} Best {category?.name}
                    </span>
                )}
            </div>
        </div>
    );
}
