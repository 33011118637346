import CallCenterIcon from "@components/svg/callCenterIcon";
import { gettingPhoneNumber } from "@lib/insurancesupersaver/callUs";
import { checkingWorkingHours } from "@lib/shared/carChexWorkingHours";
import { workingHours } from "@lib/insurancesupersaver/workingHours";

import { gettingQueriesAsString } from "@lib/shared/routerQueriesToString";
import { useRouter } from "next/router";
import React, { ReactElement } from "react";
import InsuranceSuperSaverLogo from "../logo";
import styles from "./styles.module.scss";
import colors from "@styles/variables/insurancesupersaverVariables.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";

export default function Header({
    headerMessage,
}: {
    headerMessage?: string | null | undefined;
}): ReactElement {
    const router = useRouter();
    const { category, domain } = useDomainContext();

    const isWorkingHours = checkingWorkingHours(
        router.query.callUs === "true",
        workingHours,
    );

    const handleMenuItemClick = (url: string) => {
        const zipCode = localStorage.getItem("filters")
            ? (
                  JSON.parse(localStorage.getItem("filters") as string) as {
                      [x: string]: string;
                  }
              ).zipCode
            : null;

        if (zipCode) {
            if (zipCode.length >= 4) {
                const formattedZipCode =
                    zipCode.length === 4 ? `0${zipCode}` : zipCode;

                window.location.href = `${
                    window.location.origin
                }/${url}/offers?zc=${formattedZipCode}&referrer=${
                    url ?? "home"
                }&${gettingQueriesAsString(router.query, "inline")}`;
            } else {
                window.location.href = `/${url}`;
            }
        } else {
            window.location.href = `/${url}`;
        }
    };

    return (
        <header className={styles["header"]}>
            <div className="inner max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
                <div className="md:pd-x0 flex justify-between items-center w-full">
                    <div>
                        <a href="/" aria-label="insurancesupersaver logo">
                            {domain?.logo?.fullPath ? (
                                <img
                                    src={domain.logo.fullPath}
                                    className="w-20 h-auto lg:w-32"
                                    alt="insurancesupersaver logo"
                                />
                            ) : (
                                <span className="children:w-20 children:h-7 lg:children:w-32 lg:children:h-11">
                                    <InsuranceSuperSaverLogo />
                                </span>
                            )}
                        </a>
                    </div>
                    <div>
                        {isWorkingHours &&
                        router.query?.category &&
                        gettingPhoneNumber(router.query?.category) ? (
                            <div>
                                <a
                                    className="flex items-center"
                                    href={`tel:${
                                        gettingPhoneNumber(
                                            router.query?.category,
                                        )?.value ?? ""
                                    }`}
                                >
                                    <CallCenterIcon
                                        className="w-7 h-7 lg:w-9 lg:h-9 mr-2 hidden md:inline"
                                        fill={colors.assault}
                                    />
                                    <span
                                        style={{
                                            color: colors.assault,
                                        }}
                                        className="text-sm lg:text-lg font-bold"
                                    >
                                        {
                                            gettingPhoneNumber(
                                                router.query?.category,
                                            )?.label
                                        }
                                    </span>
                                </a>
                            </div>
                        ) : headerMessage ? (
                            <div className={styles["state"]}>
                                {headerMessage}
                            </div>
                        ) : (
                            <nav>
                                <ul className="flex items-center">
                                    {domain.categories?.map(
                                        (categoryItem, index) => {
                                            if (
                                                categoryItem.slug !==
                                                category?.slug
                                            )
                                                return (
                                                    <li
                                                        className="mx-2 lg:mx-4 font-light text-xs lg:text-sm"
                                                        key={index}
                                                    >
                                                        <a
                                                            className="cursor-pointer capitalize"
                                                            onClick={() =>
                                                                handleMenuItemClick(
                                                                    categoryItem.slugAlias,
                                                                )
                                                            }
                                                        >
                                                            {
                                                                categoryItem.slugAlias
                                                            }
                                                        </a>
                                                    </li>
                                                );
                                        },
                                    )}
                                </ul>
                            </nav>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
}
