import { arrayToKeyedObject } from "@lib/sharedUtils";
import { WorkingHoursDay } from "@lib/shared/types";

const getWorkingHours = (
    currentDayName: string,
    workingDays: WorkingHoursDay[],
): WorkingHoursDay => {
    const workingDaysObject = arrayToKeyedObject<WorkingHoursDay>(
        workingDays,
        "dayName",
    );
    return workingDaysObject[currentDayName];
};

export const checkingWorkingHours = (
    overrideChecking: boolean | undefined,
    workingDays: WorkingHoursDay[],
): boolean => {
    if (overrideChecking) return true;

    const ESTOffset = 3600000 * -5;
    const currentDate = Date.parse(new Date().toString());
    const currentETSDate = new Date(currentDate + ESTOffset);
    const currentDayName = currentETSDate
        .toLocaleString("en-US", {
            weekday: "long",
        })
        .replace(/[^ -~]/g, "");
    const currentTime = currentETSDate.toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: false,
    });

    const splittedCurrentTime = currentTime.replace(/[^ -~]/g, "").split(":");
    const currentHour = parseInt(splittedCurrentTime[0], 10);
    const currentMinute = parseInt(splittedCurrentTime[1], 10);

    const currentTimeInMinutes = currentHour * 60 + currentMinute;

    const day = getWorkingHours(currentDayName, workingDays);
    if (day.isOff) return false;

    const startTime = day.startTime,
        endTime = day.endTime;

    const startHour = parseInt(startTime.split(":")[0], 10);
    const startMinute = parseInt(startTime.split(":")[1], 10);
    const startTimeInMinutes = startHour * 60 + startMinute;

    const endHour = parseInt(endTime.split(":")[0], 10);
    const endMinute = parseInt(endTime.split(":")[1], 10);
    const endTimeInMinutes = endHour * 60 + endMinute;

    if (
        startTimeInMinutes <= currentTimeInMinutes &&
        currentTimeInMinutes <= endTimeInMinutes
    ) {
        return true;
    } else {
        return false;
    }
};

export const checkingCarChexWorkingDays = (
    overrideChecking: boolean | undefined,
    workingDays: WorkingHoursDay[],
): boolean => {
    if (overrideChecking) return true;

    const ESTOffset = 3600000 * -5;
    const currentDate = Date.parse(new Date().toString());
    const currentETSDate = new Date(currentDate + ESTOffset);
    const currentDayName = currentETSDate
        .toLocaleString("en-US", {
            weekday: "long",
        })
        .replace(/[^ -~]/g, "");
    const currentTime = currentETSDate.toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: false,
    });

    const splittedCurrentTime = currentTime.replace(/[^ -~]/g, "").split(":");
    const currentHour = parseInt(splittedCurrentTime[0], 10);
    const currentMinute = parseInt(splittedCurrentTime[1], 10);

    const currentTimeInMinutes = currentHour * 60 + currentMinute;

    const day = getWorkingHours(currentDayName, workingDays);
    if (day.isOff) return false;

    const startTime = day.startTime,
        endTime = day.endTime;

    const startHour = parseInt(startTime.split(":")[0], 10);
    const startMinute = parseInt(startTime.split(":")[1], 10);
    const startTimeInMinutes = startHour * 60 + startMinute;

    const endHour = parseInt(endTime.split(":")[0], 10);
    const endMinute = parseInt(endTime.split(":")[1], 10);
    const endTimeInMinutes = endHour * 60 + endMinute;

    if (
        startTimeInMinutes <= currentTimeInMinutes &&
        currentTimeInMinutes <= endTimeInMinutes
    ) {
        return true;
    } else {
        return false;
    }
};
