import React, { ReactElement, ReactNode, useEffect } from "react";
import Head from "next/head";
import Header from "@components/insurancesupersaver/header";
import dynamic from "next/dynamic";
import Mobile from "@components/shared/blogPost/sideWidget/mobile";

import { useDispatch, useSelector } from "react-redux";
import { setModal } from "@redux/shared/actions";

import { RootState } from "@redux/reducers";

import { getCookie, setCookie } from "cookies-next";
import ListingBlock from "@components/insurancesupersaver/listingBlock";
import colors from "@styles/variables/insurancesupersaverVariables.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";

const Footer = dynamic(() => import("@components/shared/footer"));
const Modal = dynamic(() => import("@components/shared/modal"));
const CloseIcon = dynamic(() => import("@components/svg/closeIcon"));
const PrivacyPolicy = dynamic(
    () => import("@components/insurancesupersaver/legal/privacyPolicy"),
);
const TermsAndConditions = dynamic(
    () => import("@components/insurancesupersaver/legal/termsAndConditions"),
);

export default function InsuranceSuperSaverWrapper({
    headerMessage,
    page,
    children,
}: {
    headerMessage?: string | null | undefined;
    page: "listingPage" | "categoryPage" | "homePage";
    children: ReactNode;
}): ReactElement {
    const dispatch = useDispatch();
    const {
        category,
        clicksOffers: offers,
        zipCodeLocation,
        visitDetails,
    } = useDomainContext();

    const { active: modalActive, content: modalContent } = useSelector(
        (state: RootState) => state.rootReducer.shared.modal,
    );

    const state = zipCodeLocation ? zipCodeLocation.city : visitDetails?.region;

    const handleClose = () => {
        dispatch(setModal({ active: false, content: undefined }));
    };

    useEffect(() => {
        if (
            page === "categoryPage" &&
            (visitDetails?.trafficSourceType === "Social Media" ||
                visitDetails?.trafficSourceNetwork === "Propel Media")
        ) {
            window.setTimeout(() => {
                if (offers?.length) {
                    timerMouseLeave();
                }
            }, 3000);
        }
    }, [visitDetails]);

    const timerMouseLeave = () => {
        handleModalCookie(
            `${category?.slugAlias ?? ""}-category-page-modal-shown`,
        );
    };

    const handleMouseLeave = () => {
        if (
            document.activeElement?.tagName === "INPUT" ||
            document.activeElement?.tagName === "SELECT" ||
            page === "homePage" ||
            !offers?.length ||
            (visitDetails?.trafficSourceType !== "Social Media" &&
                visitDetails?.trafficSourceNetwork !== "Propel Media")
        )
            return;

        let cookieText = "-modal-shown";
        if (page === "categoryPage") {
            cookieText = "-category-page-modal-shown";
        }

        handleModalCookie(`${category?.slugAlias ?? ""}${cookieText}`);
    };

    const handleModalCookie = (cookieName: string) => {
        if (
            !getCookie(cookieName) ||
            parseInt(getCookie(cookieName) as string, 10) < 2
        ) {
            dispatch(setModal({ active: true, content: page }));
            setCookie(
                cookieName,
                getCookie(cookieName)
                    ? parseInt(getCookie(cookieName) as string, 10) + 1
                    : 1,
            );
        }
    };

    const getModalContent = () => {
        switch (modalContent) {
            case "privacy":
                return <PrivacyPolicy />;
            case "terms":
                return <TermsAndConditions />;
            case "listingPage":
                return (
                    <>
                        <h3
                            style={{ color: colors.assault }}
                            className="font-bold text-xl mb-4 lg:px-4"
                        >
                            Check {state} Top {category?.name} Company
                        </h3>
                        {offers && offers[0] && (
                            <ListingBlock
                                item={offers[0]}
                                state={state}
                                category={category ?? null}
                                type="listingModal"
                                handleModalClose={handleClose}
                                index={0}
                            />
                        )}
                    </>
                );
            case "categoryPage":
                return (
                    <>
                        <h3
                            style={{ color: colors.assault }}
                            className="font-bold text-xl mb-4 lg:px-4"
                        >
                            Check {state} Top {category?.name} Companies
                        </h3>
                        {offers?.slice(0, 2).map((offer, index) => {
                            return (
                                <ListingBlock
                                    key={index}
                                    item={offer}
                                    state={state}
                                    category={category ?? null}
                                    type="categoryListingModal"
                                    handleModalClose={handleClose}
                                    index={index}
                                />
                            );
                        })}
                    </>
                );
            case "sideWidget":
                return <Mobile />;
            default:
                return null;
        }
    };

    return (
        <>
            <Head>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap"
                    rel="stylesheet"
                />
            </Head>

            <div
                style={{ fontFamily: `'Poppins', sans-serif` }}
                onMouseLeave={handleMouseLeave}
            >
                <Header headerMessage={headerMessage} />
                {/* <main className="max-w-7xl mx-auto block px-4vw lg:px-4"> */}
                {children}
                {/* </main> */}
                <Footer textColor={colors.blueAster} />
                <Modal
                    onClose={handleClose}
                    active={modalActive}
                    closeIcon={<CloseIcon />}
                    backdropClosable
                    modalStyles={
                        modalContent === "listingPage" ||
                        modalContent === "categoryPage"
                            ? {
                                  display: "flex",
                                  flexDirection: "column",
                                  padding: "30px 20px",
                                  paddingBottom: "5px",
                                  fontFamily: `'Poppins', sans-serif`,
                                  borderRadius: "10px",
                              }
                            : {
                                  display: "flex",
                                  flexDirection: "column",
                                  maxHeight:
                                      modalContent === "sideWidget"
                                          ? "80vh"
                                          : "100%",
                              }
                    }
                    modalClassName={
                        modalContent === "listingPage" ||
                        modalContent === "categoryPage"
                            ? undefined
                            : "h-[90vh] rounded"
                    }
                    bodyStyles={
                        modalContent === "listingPage" ||
                        modalContent === "categoryPage"
                            ? undefined
                            : { overflowY: "scroll" }
                    }
                    width={
                        modalContent === "listingPage" ||
                        modalContent === "categoryPage"
                            ? 500
                            : 900
                    }
                    header={
                        modalContent === "privacy"
                            ? "Privacy Policy"
                            : modalContent === "terms"
                            ? "Terms and Conditions"
                            : modalContent === "sideWidget"
                            ? "Contents"
                            : undefined
                    }
                >
                    {getModalContent()}
                </Modal>
            </div>
        </>
    );
}
