export const workingHours = [
    {
        dayName: "Saturday",
        startTime: "8:00",
        endTime: "17:00",
        isOff: true,
    },
    {
        dayName: "Sunday",
        startTime: "8:00",
        endTime: "17:00",
        isOff: true,
    },
    {
        dayName: "Monday",
        startTime: "8:00",
        endTime: "17:00",
        isOff: false,
    },
    {
        dayName: "Tuesday",
        startTime: "8:00",
        endTime: "17:00",
        isOff: false,
    },
    {
        dayName: "Wednesday",
        startTime: "8:00",
        endTime: "17:00",
        isOff: false,
    },
    {
        dayName: "Thursday",
        startTime: "8:00",
        endTime: "17:00",
        isOff: false,
    },
    {
        dayName: "Friday",
        startTime: "8:00",
        endTime: "17:00",
        isOff: false,
    },
];
