export const checkingWorkingHours = (
    overrideChecking?: boolean | undefined,
): boolean => {
    if (overrideChecking) return true;

    const currentDate = new Date();

    const currentDayName = currentDate.toLocaleString("en-US", {
        weekday: "long",
    });
    const currentTime = currentDate.toLocaleTimeString("en-US", {
        timeZone: "EST",
        hour12: false,
    });

    const currentHour = parseInt(currentTime.split(":")[0], 10);
    const currentMinute = parseInt(currentTime.split(":")[1], 10);

    const currentTimeInMinutes = currentHour * 60 + currentMinute;

    let startTime, endTime;

    switch (currentDayName) {
        case "Monday":
        case "Tuesday":
        case "Wednesday":
        case "Thursday":
        case "Friday":
            startTime = "8:00";
            endTime = "17:00";
            break;
        default:
            return false;
    }

    const startHour = parseInt(startTime.split(":")[0], 10);
    const startMinute = parseInt(startTime.split(":")[1], 10);
    const startTimeInMinutes = startHour * 60 + startMinute;

    const endHour = parseInt(endTime.split(":")[0], 10);
    const endMinute = parseInt(endTime.split(":")[1], 10);
    const endTimeInMinutes = endHour * 60 + endMinute;

    if (
        startTimeInMinutes <= currentTimeInMinutes &&
        currentTimeInMinutes <= endTimeInMinutes
    ) {
        return true;
    } else {
        return false;
    }
};

export const gettingPhoneNumber = (
    category,
): { label: string; value: string } | null => {
    const callCenterPhoneNumber = {
        label: "",
        value: "",
    };

    switch (category) {
        case "life":
            callCenterPhoneNumber["label"] = "(844) 720-7786";
            callCenterPhoneNumber["value"] = "844-720-7786";
            return callCenterPhoneNumber;
        case "health":
            callCenterPhoneNumber["label"] = "(833) 447-1422";
            callCenterPhoneNumber["value"] = "833-447-1422";
            return callCenterPhoneNumber;
        case "medicare":
            callCenterPhoneNumber["label"] = "(833) 443-1562";
            callCenterPhoneNumber["value"] = "833-443-1562";
            return callCenterPhoneNumber;
        default:
            null;
    }

    return null;
};
